'use strict';
var angular = require('angular');
require('angular-ui-router');
require('angular-material');
require('angular-cookies');

var modulename = 'auth';

module.exports = function (namespace) {
    var shared = require('../shared')(namespace);
    var fullname = namespace + '.' + modulename;

    var app = angular.module(fullname, ['ui.router', 'ngMaterial', 'ngCookies', shared.name]);
    // inject:folders start
    require('./controllers')(app);
    require('./services')(app);
    // inject:folders end
    app.namespace = app.namespace || {};
    app.namespace.shared = shared.name;

    var configRoutesDeps = ['$stateProvider', '$urlRouterProvider'];
    var configRoutes = function ($stateProvider, $urlRouterProvider) {
        $urlRouterProvider.otherwise('/');
        $stateProvider
            .state('auth', {
                url: '/login',
                parent: 'shared',
                views: {
                    'content': {
                        template: require('./views/login.html'),
                        controller: app.name + '.login as login'
                    }
                }
            })
            .state('home', {
                url: '/',
                parent: 'shared',
                views: {
                    'sidenav': {
                        template: require('../shared/views/sidenav.html'),
                        controller: app.namespace.shared + '.sidenav as sidenav'
                    },
                    'toolbar': {
                        template: require('../shared/views/toolbar.html'),
                        controller: app.namespace.shared + '.toolbar as toolbar'
                    },
                    'content': {
                        template: require('./views/home.html'),
                        controller: app.name + '.home as home'
                    }
                }
            })
        ;
    };
    configRoutes.$inject = configRoutesDeps;
    app.config(configRoutes);

    var configRestDeps = ['RestangularProvider', '$injector', app.name + '.tokenHandlerProvider', app.name + '.authHandlerProvider'];
    var configRest = function (RestangularProvider, $injector, TokenHandlerProvider, AuthHandlerProvider) {
        var TokenHandler = $injector.instantiate(TokenHandlerProvider.$get);
        var AuthHandler = $injector.instantiate(AuthHandlerProvider.$get);

        var root_path = '';
        //var root_path = "http://be.ristorapp.paolinux.com/api/v1/";
        //var root_path = "http://be.ristorapp.lo/app_dev.php/api/v1/";
        if (window.location.hostname === 'localhost') {
            root_path = 'http://127.0.0.1:8000/app_dev.php/api/v1/';
        } else {
            root_path = 'https://be.ristorapp.paolinux.com/api/v1/';
        }

        RestangularProvider
            .setBaseUrl(root_path)
            .addRequestInterceptor(function (element, operation, what, url) {
                var newRequest = {};
                if (operation === 'post' || operation === 'put') {
                    what = what.split('');
                    what.pop();
                    what = what.join('');
                }
                if (operation === 'put') {
                    delete element._links;
                }
                newRequest[what] = element;
                return newRequest;
            })
            .setRestangularFields({
                selfLink: '_links.self.href'
            })
            .addFullRequestInterceptor(function (element, operation, route, url, headers, params, httpConfig) {

                headers['X-WSSE'] =
                    TokenHandler.getCredentials(
                        AuthHandler.username(),
                        AuthHandler.secret()
                    );

                return {
                    element: element,
                    headers: headers,
                    params: params,
                    httpConfig: httpConfig
                };
            });
    };
    configRest.$inject = configRestDeps;
    app.config(configRest);

    return app;
};
