'use strict';
var servicename = 'reportService';

module.exports = function(app) {

    var dependencies = ['Restangular', 'FileSaver', 'Blob'];

    function service(Restangular, FileSaver, Blob) {
        var jasperreport = function(report) {
            var param = {
                report: report
            };

            Restangular
                .one('warehouses').one('jasperreport').withHttpConfig({responseType: 'arraybuffer'}).customGET('', param)
                .then(
                    function (response) {
                        var file = new Blob([response], {type: 'application/pdf'});
                        FileSaver.saveAs(file, 'magazzino.pdf');
                    }
                );
        };

        return {
            jasperreport: jasperreport
        };

    }
    service.$inject = dependencies;
    app.factory(app.name + '.' + servicename, service);
};