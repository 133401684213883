'use strict';
var controllername = 'stockManagementIndex';

var angular = require('angular');
var moment = require('moment');

module.exports = function (app) {
    var fullname = app.name + '.' + controllername;
    /*jshint validthis: true */

    var deps = ['$cookies', 'toastr', '$timeout', 'Restangular', 'main.stockManagement.reportService'];

    function controller($cookies, toastr, $timeout, Restangular, reportService) {
        var vm = this;
        vm.controllername = fullname;
        vm.roles = $cookies.get('roles');

        vm.warehouseManagements = [];
        vm.warehouseManagements.totalCount = 0;
        vm.formLoadManaged = {};
        vm.formUnloadManaged = {};
        vm.formLoad = {};
        vm.formUnload = {};
        vm.formUnloadManaged.qty = 1;
        vm.formWarehouseCart = {};

        vm.oldest = [];
        vm.currentIdArticlePackaging = [];
        vm.currentIdArticle = [];
        vm.selectedWarehouseCart = {};
        vm.currentWarehouseCartRow = {};
        vm.locationValid = true;
        vm.listArticleCodeValid = false;
        vm.submitted = false;

        vm.warehouseCartRowList = [];

        vm.query = {
            order: '-a.id',
            limit: 10,
            page: 1
        };

        vm.limitOptions = [10, 100];

        var playSound = function(mp3File){
            var audioElement = document.createElement('audio');
            audioElement.setAttribute('src', '/fonts/' + mp3File);
            audioElement.play();
        };

        vm.getArticleByItem = function (item) {
            vm.currentIdArticlePackaging = [];
            if (item) {
                // console.log('currentIdArticlePackaging', item);
                vm.currentIdArticlePackaging = item;
                vm.currentIdArticle = item.idArticle;
                Restangular.one('oldest').one('article').get({'idArticle': item.idArticle.id}).then(function (data) {
                    // console.log('oldest', data.items);
                    vm.oldest = data.items;
                });
            }
        };

        vm.getArticleByCode = function (code) {
            vm.currentIdArticlePackaging = [];
            if (code) {
                Restangular.one('article').one('packaging').one('by').one('code').get({'code': code}).then(function (item) {
                    vm.currentIdArticlePackaging = item[0];
                    vm.currentIdArticle = item[0].idArticle;
                    // console.log('item', item);
                    return Restangular.one('oldest').one('article').get({'idArticle': item[0].idArticle.id}).then(function (data) {
                        // console.log('oldest', data.items);
                        vm.oldest = data.items;
                    });
                });
            }
        };

        vm.checkListArticleCode = function (code) {
            vm.elementToFocus = '';
            vm.currentListIdArticlePackaging = [];
            if (code) {
                Restangular.one('article').one('packaging').one('by').one('code').get({'code': code}).then(function (item) {
                    vm.currentListIdArticlePackaging = item[0];
                    if (item[0]) {
                        var idArticle = item[0].idArticle.id;
                        if (idArticle === vm.currentWarehouseCartRow.idArticle.id) {
                            // id sparato è corretto
                            vm.listArticleCodeValid = true;
                            playSound('ok.mp3');
                        } else {
                            // id sparato non è corretto
                            toastr.error('Attenzione !!! Il codice sparato non è corretto.');
                            vm.listArticleCodeValid = false;
                            vm.formWarehouseCart.code = '';
                            vm.elementToFocus = 'codeWarehouseCart';
                            playSound('error.mp3');
                        }
                    } else {
                        // id sparato non è corretto
                        toastr.error('Attenzione !!! Il codice sparato non è corretto.');
                        vm.listArticleCodeValid = false;
                        vm.formWarehouseCart.code = '';
                        vm.elementToFocus = 'codeWarehouseCart';
                        playSound('error.mp3');
                    }
                });
            }
        };

        vm.checkLoadManagedLocation = function () {
            vm.elementToFocus = '';
            if (vm.oldest && vm.oldest.length > 0) { // !vm.formLoadManaged.idArticlePackaging.idArticle.hasLot
                $timeout(function () {
                    vm.oldest.forEach(function (curOldest) {
                        if (vm.formLoadManaged.location === curOldest.location) {
                            vm.locationValid = false;
                            vm.formLoadManaged.location = '';
                            vm.elementToFocus = 'positionLoadManaged';
                        }
                    });
                }, 250);
            }
        };

        vm.checkWarehouseCartLocation = function () {
            vm.elementToFocus = '';
            if (vm.formWarehouseCart.location) {
                $timeout(function () {
                    if (vm.formWarehouseCart.location === vm.oldest[0].location) {
                        playSound('ok.mp3');
                    } else {
                        vm.formWarehouseCart.location = '';
                        vm.elementToFocus = 'locationWarehouseCart';
                        playSound('error.mp3');
                    }
                }, 250);
            }
        };

        vm.checkUnloadManagedLocation = function () {
            vm.elementToFocus = '';
            $timeout(function () {
                if (vm.formUnloadManaged.location !== vm.oldest[0].location) {
                    vm.locationValid = false;
                    vm.formUnloadManaged.location = '';
                    vm.elementToFocus = 'locationUnloadManaged';
                }
            }, 250);
        };

        vm.checkUnloadTotQty = function () {
            $timeout(function () {
                if (parseInt(vm.formUnload.qty) > parseInt(vm.oldest[0].totWeight / vm.currentIdArticlePackaging.idPackaging.qtyMultiplier)) {
                    vm.totQty = false;
                    vm.formUnload.qty = '';
                }
            }, 250);
        };

        function selectCurrentWarehouseCartRow() {
            vm.currentWarehouseCartRow = [];
            vm.currentIdArticlePackaging = [];
            for (var i = 0; i < vm.warehouseCartRowList.length; i++) {
                if (vm.warehouseCartRowList[i].qty > vm.warehouseCartRowList[i].totUnloaded) {
                    vm.currentWarehouseCartRow = vm.warehouseCartRowList[i];
                    // console.log('currentWarehouseCartRow', vm.currentWarehouseCartRow);
                    // var code = vm.currentWarehouseCartRow.idArticlePackaging.code;
                    // vm.getArticleByCode(code);
                    vm.getArticleByItem(vm.warehouseCartRowList[i]);
                    vm.elementToFocus = 'codeWarehouseCart';
                    break;
                }
            }
        }

        vm.nextWarehouseCartItem = function() {
            vm.elementToFocus = '';
            for (var i = 0; i < vm.warehouseCartRowList.length; i++) {
                if (vm.warehouseCartRowList[i].id === vm.currentWarehouseCartRow.id) {
                    vm.warehouseCartRowList[i].totUnloaded = vm.warehouseCartRowList[i].qty;
                    vm.warehouseCartRowList[i].isComplete = true;
                    vm.currentWarehouseCartRow.totUnloaded = vm.warehouseCartRowList[i].qty;
                    vm.currentWarehouseCartRow.isComplete = true;
                    vm.formWarehouseCart.code = '';
                    vm.formWarehouseCart.location = '';
                    selectCurrentWarehouseCartRow();
                    vm.elementToFocus = 'codeWarehouseCart';
                    break;
                }
            }
            // console.log(vm.warehouseCartRowList);
            // console.log(vm.currentWarehouseCartRow);
        };

        vm.loadWarehouseCart = function () {
            vm.promise = Restangular
                .one('warehouses').one('carts').one('list').get({id: vm.selectedWarehouseCart})
                .then(
                    function (response) {
                        vm.warehouseCartRowList = response.items[0].idWarehouseCartWarehouseCartRow;
                        selectCurrentWarehouseCartRow();
                    }
                );
        };

        function getWarehouseManagements(query) {
            vm.promise = Restangular
                .one('warehouses').one('list').get(query)
                .then(
                    function (response) {
                        vm.warehouseManagements = response;
                    }
                );
        }

        vm.loadManaged = function () {
            vm.elementToFocus = '';
            vm.submitted = true;
            vm.formLoadManaged.idArticlePackaging = vm.currentIdArticlePackaging;
            vm.formLoadManaged.mov = '+';
            vm.formLoadManaged.movDate = moment().format('YYYY-MM-DD');
            vm.formLoadManaged.user = $cookies.get('username');
            Restangular
                .one('warehouses').customPOST(vm.formLoadManaged).then(
                function () {
                    toastr.success('Movimento di magazzino aggiunto con successo.');
                    vm.oldest = [];
                    vm.currentIdArticlePackaging = [];
                    vm.formLoadManaged.code = '';
                    vm.formLoadManaged.location = '';
                    vm.formLoadManaged.lot = null;
                    vm.formLoadManaged.expirationDate = null;
                    vm.formLoadManaged.qty = '';
                    vm.elementToFocus = 'codeLoadManaged';
                    getWarehouseManagements(vm.query);
                    vm.submitted = false;
                },
                function (res) {
                    toastr.error('Impossibile salvare, ' + res.statusText + '.');
                    vm.submitted = false;
                }
            );
        };

        vm.unloadManaged = function () {
            vm.elementToFocus = '';
            vm.submitted = true;
            vm.formUnloadManaged.idArticlePackaging = vm.currentIdArticlePackaging;
            vm.formUnloadManaged.expirationDate = vm.oldest[0].expirationDate;
            vm.formUnloadManaged.mov = '-';
            vm.formUnloadManaged.movDate = moment().format('YYYY-MM-DD');
            vm.formUnloadManaged.user = $cookies.get('username');
            Restangular
                .one('warehouses').customPOST(vm.formUnloadManaged).then(
                function () {
                    toastr.success('Movimento di magazzino aggiunto con successo.');
                    vm.formUnloadManaged = {};
                    vm.formUnloadManaged.qty = 1;
                    vm.oldest = [];
                    vm.currentIdArticlePackaging = [];
                    vm.elementToFocus = 'codeUnloadManaged';
                    getWarehouseManagements(vm.query);
                    vm.submitted = false;
                },
                function (res) {
                    toastr.error('Impossibile salvare, ' + res.statusText + '.');
                    vm.submitted = false;
                }
            );
        };

        vm.clearSearch = function () {
            vm.formSearch = {};
        };

        vm.load = function () {
            vm.submitted = true;
            vm.formLoad.mov = '+';
            vm.formLoad.movDate = moment().format('YYYY-MM-DD');
            vm.formLoad.user = $cookies.get('username');
            Restangular
                .one('warehouses').customPOST(vm.formLoad).then(
                function () {
                    toastr.success('Movimento di magazzino aggiunto con successo.');
                    vm.formLoad.code = '';
                    vm.formLoad.location = '';
                    vm.formLoad.lot = null;
                    vm.formLoad.expirationDate = null;
                    vm.formLoad.qty = '';
                    vm.formLoad.idArticlePackaging = [];
                    getWarehouseManagements(vm.query);
                    vm.submitted = false;
                },
                function (res) {
                    toastr.error('Impossibile salvare, ' + res.statusText + '.');
                    vm.submitted = false;
                }
            );
        };

        vm.unload = function () {
            vm.submitted = true;
            vm.formUnload.expirationDate = vm.oldest[0].expirationDate;
            vm.formUnload.mov = '-';
            vm.formUnload.movDate = moment().format('YYYY-MM-DD');
            vm.formUnload.user = $cookies.get('username');
            Restangular
                .one('warehouses').customPOST(vm.formUnload).then(
                function () {
                    toastr.success('Movimento di magazzino aggiunto con successo.');
                    vm.formUnload = {};
                    vm.oldest = [];
                    getWarehouseManagements(vm.query);
                    vm.submitted = false;
                },
                function (res) {
                    toastr.error('Impossibile salvare, ' + res.statusText + '.');
                    vm.submitted = false;
                }
            );
        };

        vm.unloadWarehouseCart = function () {
            vm.elementToFocus = '';
            vm.submitted = true;
            vm.formWarehouseCart.idArticlePackaging = vm.currentListIdArticlePackaging;
            vm.formWarehouseCart.expirationDate = vm.oldest[0].expirationDate;
            vm.formWarehouseCart.mov = '-';
            vm.formWarehouseCart.qty = 1;
            vm.formWarehouseCart.movDate = moment().format('YYYY-MM-DD');
            vm.formWarehouseCart.idWarehouseCartRow = {id: vm.currentWarehouseCartRow.id};
            vm.formWarehouseCart.user = $cookies.get('username');
            Restangular
                .one('warehouses').customPOST(vm.formWarehouseCart).then(
                function () {
                    toastr.success('Movimento di magazzino aggiunto con successo.');
                    vm.formWarehouseCart = {};
                    vm.oldest = [];
                    vm.loadWarehouseCart();
                    // selectCurrentWarehouseCartRow();
                    getWarehouseManagements(vm.query);
                    vm.submitted = false;
                    vm.elementToFocus = 'codeWarehouseCart';
                },
                function (res) {
                    toastr.error('Impossibile salvare, ' + res.statusText + '.');
                    vm.submitted = false;
                }
            );
        };

        vm.loadManagedSelectedTab = function () {
            vm.elementToFocus = 'codeLoadManaged';
        };

        vm.loadManagedUnselectTab = function () {
            vm.formLoadManaged = {};
            vm.oldest = [];
            vm.currentIdArticlePackaging = [];
        };

        vm.unloadManagedSelectedTab = function () {
            vm.elementToFocus = 'codeUnloadManaged';
        };

        vm.unloadManagedUnselectTab = function () {
            vm.formUnloadManaged = {};
            vm.formUnloadManaged.qty = 1;
            vm.oldest = [];
            vm.currentIdArticlePackaging = [];
        };

        vm.searchSelectedTab = function () {
            vm.elementToFocus = '';
        };

        vm.searchUnselectTab = function () {
            vm.formSearch = {};
            vm.oldest = [];
            vm.currentIdArticlePackaging = [];
        };

        vm.warehouseCartSelectedTab = function () {
            vm.elementToFocus = '';
            vm.warehouseCarts = [];
            // Restangular.one('warehouses').one('carts').one('list').customGET('', {complete: true}).then(function (data) {
            Restangular.one('warehouses').one('carts').one('list').get().then(function (data) {
                data.items.forEach(function (r) {
                    if (!r.isComplete) {
                        vm.warehouseCarts.push(r);
                    }
                });
                // vm.warehouseCarts = data.items;
                if (angular.isNumber(vm.selectedWarehouseCart)) {
                    vm.loadWarehouseCart();
                }
            });
        };

        vm.warehouseCartUnselectTab = function () {
            vm.formWarehouseCart = {};
            vm.formWarehouseCart.qty = 1;
            vm.warehouseCarts = [];
            vm.oldest = [];
            vm.currentIdArticlePackaging = [];
        };

        vm.loadSelectedTab = function () {
            vm.elementToFocus = '';
        };

        vm.loadUnselectTab = function () {
            vm.oldest = [];
            vm.currentIdArticlePackaging = [];
        };

        vm.unloadSelectedTab = function () {
            vm.elementToFocus = '';
        };

        vm.unloadUnselectTab = function () {
            vm.oldest = [];
            vm.currentIdArticlePackaging = [];
        };

        vm.jasperreport = function (report) {
            reportService.jasperreport(report);
        };

        vm.onPaginate = function () {
            getWarehouseManagements(vm.query);
        };

        getWarehouseManagements(vm.query);
    }

    controller.$inject = deps;
    app.controller(fullname, controller);
};
