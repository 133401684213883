'use strict';
var angular = require('angular');
require('angular-ui-router');
require('angular-material');

var modulename = 'shared';

module.exports = function (namespace) {

    var fullname = namespace + '.' + modulename;

    var app = angular.module(fullname, ['ui.router', 'ngMaterial']);
    // inject:folders start
    require('./controllers')(app);
require('./directives')(app);
    // inject:folders end
    app.namespace = app.namespace || {};

    var configRoutesDeps = ['$stateProvider', '$urlRouterProvider'];
    var configRoutes = function ($stateProvider, $urlRouterProvider) {
        $urlRouterProvider.otherwise('/');
        $stateProvider.state('shared', {
            abstract: true,
            views: {
                'layout': {
                    template: require('./views/layout.html'),
                    controller: app.name + '.layout as layout'
                }
            }
        });
    };
    configRoutes.$inject = configRoutesDeps;
    app.config(configRoutes);

    return app;
};
