'use strict';

var namespace = 'main';
// fix protractor issue
if (window.location.toString().indexOf('localhost:5555') > 0) {
    window.name = 'NG_DEFER_BOOTSTRAP!NG_ENABLE_DEBUG_INFO!';
}
var angular = require('angular');
require('angular-ui-router');
require('angular-material');
require('angular-cookies');
require('restangular');
window._ = require('lodash');
require('angular-material-data-table');
var moment = require('moment');
require('angular-toastr');
// require('angular-loading-overlay');

var app = angular.module(namespace, [
    'ngMaterial',
    'restangular',
    'md.data.table',
    'toastr',
    // 'bsLoadingOverlay',
    // inject:modules start
    require('./article')(namespace).name,
    require('./articlePackaging')(namespace).name,
    require('./auth')(namespace).name,
    require('./codeTemplate')(namespace).name,
    require('./packaging')(namespace).name,
    require('./registry')(namespace).name,
    require('./shared')(namespace).name,
    require('./stockManagement')(namespace).name,
    require('./warehouseCart')(namespace).name,
    require('./warehouseManagement')(namespace).name
    // inject:modules end
]);

if (process.env.SENTRY_MODE === 'prod') {
    var configCompileDeps = ['$compileProvider'];
    var configCompile = function ($compileProvider) {
        $compileProvider.debugInfoEnabled(false);
    };
    configCompile.$inject = configCompileDeps;
    app.config(configCompile);
}

var runDeps = [];
var run = function () {
};

run.$inject = runDeps;
app.run(run);

var configDeps = ['$locationProvider', '$mdDateLocaleProvider', 'toastrConfig', '$qProvider'];
var config = function($locationProvider, $mdDateLocaleProvider, toastrConfig, $qProvider) {

    $locationProvider.html5Mode({
        enabled: false,
        requireBase: false
    }).hashPrefix('');

    $mdDateLocaleProvider.formatDate = function(date) {
        return date ? moment(date).format('DD/MM/YYYY') : '';
    };
    $mdDateLocaleProvider.parseDate = function(dateString) {
        var m = moment(dateString, 'YYYY-MM-DD');
        return m.isValid() ? m.toDate() : new Date(NaN);
    };

    angular.extend(toastrConfig, {
        autoDismiss: false,
        containerId: 'toast-container',
        maxOpened: 0,
        newestOnTop: true,
        positionClass: 'toast-bottom-left',
        preventDuplicates: false,
        preventOpenDuplicates: false,
        target: 'body'
    });
};

config.$inject = configDeps;
app.config(config);

module.exports = app;
