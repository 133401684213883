'use strict';

module.exports = function(app) {
    // inject:start
    require('./authHandler.service')(app);
    require('./base64.service')(app);
    require('./digest.service')(app);
    require('./tokenHandler.service')(app);
    // inject:end
};
