'use strict';
var controllername = 'articleItem';

module.exports = function(app) {
    var fullname = app.name + '.' + controllername;
    /*jshint validthis: true */

    var deps = ['$mdDialog', 'toastr', 'Restangular', 'id'];

    function controller($mdDialog, toastr, Restangular, id) {
        var vm = this;
        vm.controllername = fullname;
        vm.id = id;
        vm.formData = {};
        var deletedRows = [];

        function getArticlePackagings() {
            var apObj = [];
            var query = {
                isDefault: 1
            };
            vm.promise = Restangular
                .one('packagings').one('list').get(query)
                .then(
                    function(response) {
                        for (var i = 0; i < response.items.length; i++) {
                            apObj[i] = {
                                idPackaging: response.items[i],
                                totalMultiplier: response.items[i].qtyMultiplier
                            };
                        }
                        vm.formData.idArticleArticlePackaging = apObj;
                        // vm.formData.idArticleArticlePackaging = response.items;
                    }
                );
        }

        if (id) {
            Restangular
                .one('articles').customGET('', {'id': id}).then(function (data) {
                vm.formData = data;
                //vm.articlePackagings = data.idArticleArticlePackaging;
            });
        } else {
            getArticlePackagings();
        }

        vm.deleteArticlePackaging = function (articlePackaging) {
            // console.log('delete articlePackaging');
            var idArticleArticlePackagings = vm.formData.idArticleArticlePackaging;
            for (var i = 0; i < idArticleArticlePackagings.length; i++) {
                if (idArticleArticlePackagings[i].id === articlePackaging.id) {
                    vm.formData.idArticleArticlePackaging.splice(i, 1);
                    deletedRows.push(articlePackaging.id);
                }
            }
        };

        vm.addArticlePackaging = function () {
            vm.formData.idArticleArticlePackaging[vm.formData.idArticleArticlePackaging.length] = vm.formData.id ? {idArticle: {id: vm.formData.id}} : {};
        };

        vm.confirm = function (closeDialog) {
            if (vm.id) {
                vm.formData.idArticleArticlePackagingDeleted = deletedRows;
                Restangular
                    .one('articles').customPUT(vm.formData).then(
                    function (data) {
                        if (!closeDialog) {
                            $mdDialog.hide(true);
                        } else {
                            vm.formData = {};
                            getArticlePackagings();
                        }
                        toastr.success('Articolo salvato con successo.');
                    },
                    function (res) {
                        toastr.error('Impossibile salvare, ' + res.statusText + '.');
                    }
                );
            } else {
                //vm.formData.idArticleArticlePackaging = vm.articlePackagings;
                Restangular
                    .one('articles').customPOST(vm.formData).then(
                    function (data) {
                        if (!closeDialog) {
                            $mdDialog.hide(true);
                        } else {
                            vm.formData = {};
                            getArticlePackagings();
                        }
                        toastr.success('Articolo aggiunto con successo.');
                    },
                    function (res) {
                        toastr.error('Impossibile salvare, ' + res.statusText + '.');
                    }
                );
            }

        };

        vm.cancel = function () {
            $mdDialog.hide();
            toastr.info('Operazione annullata.');
        };
    }

    controller.$inject = deps;
    app.controller(fullname, controller);
};
