'use strict';
var servicename = 'dataService';

module.exports = function(app) {

    var dependencies = ['Restangular'];

    function service(Restangular) {
        var getArticleByCode = function(code) {
            return Restangular.one('article').one('packaging').one('by').one('code').get({'code': code}).then(function (data) {
                return data;
            });
        };

        return {
            getArticleByCode: getArticleByCode
        };

    }
    service.$inject = dependencies;
    app.factory(app.name + '.' + servicename, service);
};