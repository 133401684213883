'use strict';
 /*eslint consistent-this:[2,  "nextOnEnterCtrl"] */
var directivename = 'nextOnEnter';

module.exports = function(app) {

    // controller
    var controllerDeps = [];
    var controller = function() {
        var nextOnEnterCtrl = this;
        nextOnEnterCtrl.directivename = directivename;
    };
    controller.$inject = controllerDeps;

    /*eslint-disable consistent-this */

    // directive
    var directiveDeps = [];
    var directive = function() {
        return {
            restrict: 'A',
            link: function ($scope, selem, attrs) {
                selem.bind('keydown', function (e) {
                    var code = e.keyCode || e.which;
                    if (code === 13) {
                        e.preventDefault();
                        var pageElems = document.querySelectorAll('input:not([disabled]), select, textarea');
                        var elem = e.srcElement;
                        var focusNext = false;
                        var len = pageElems.length;
                        for (var i = 0; i < len; i++) {
                            var pe = pageElems[i];
                            if (focusNext) {
                                if (pe.style.display !== 'none') {
                                    pe.focus();
                                    break;
                                }
                            } else if (pe === e.srcElement) {
                                focusNext = true;
                            }
                        }
                    }
                });
            }
        };
    };
    directive.$inject = directiveDeps;

    app.directive(directivename, directive);
};
