'use strict';
var controllername = 'minimumStockSupply';

module.exports = function(app) {
    var fullname = app.name + '.' + controllername;
    /*jshint validthis: true */

    var deps = ['$scope', '$mdDialog', '$mdSidenav', 'Restangular', 'FileSaver', 'Blob'];

    function controller($scope, $mdDialog, $mdSidenav, Restangular, FileSaver, Blob) {
        var vm = this;
        vm.articles = [];
        vm.articles.totalCount = 0;
        vm.controllername = fullname;

        vm.query = {
            order: 'b.name, a.name',
            limit: 500,
            page: 1,
            minStock: true
        };

        vm.limitOptions = [50, 250];
        // vm.limitOptions = [50, 250, {
        //     label: 'All',
        //     value: function () {
        //         return vm.articlePackagings.totalCount;
        //     }
        // }];

        function getArticles(query) {
            vm.promise = Restangular
                .one('articles').one('list').get(query)
                .then(
                    function (response) {
                        vm.articles = response;
                    }
                );
        }

        vm.closeAlert = function () {
            $mdDialog.hide();
        };

        vm.printItems = function () {
            vm.pdf = Restangular
                .one('articles').one('minimum').one('stock').one('pdf').one('list').withHttpConfig({responseType: 'arraybuffer'}).customGET('', vm.query)
                .then(
                    function (response) {
                        var file = new Blob([response], {type: 'application/pdf'});
                        FileSaver.saveAs(file, 'elenco_articoli.pdf');
                    }
                );
        };

        vm.onPaginate = function () {
            getArticles(vm.query);
        };

        vm.onReorder = function () {
            getArticles(vm.query);
        };

        getArticles(vm.query);
    }

    controller.$inject = deps;
    app.controller(fullname, controller);
};
