'use strict';
var controllername = 'articleIndex';

var articleItem = require('../views/articleItem.html');
var articleDelete = require('../views/articleDelete.html');
var articlePrintItems = require('../views/articlePrintItems.html');

module.exports = function (app) {
    var fullname = app.name + '.' + controllername;
    /*jshint validthis: true */

    var deps = ['$scope', '$mdDialog', '$mdSidenav', 'Restangular', 'FileSaver', 'Blob'];

    function controller($scope, $mdDialog, $mdSidenav, Restangular, FileSaver, Blob) {
        var vm = this;
        vm.articles = [];
        vm.articles.totalCount = 0;
        vm.controllername = fullname;
        vm.selected = [];

        vm.query = {
            order: '-a.id',
            limit: 50,
            page: 1
        };

        vm.limitOptions = [50, 250];
        // vm.limitOptions = [50, 250, {
        //     label: 'All',
        //     value: function () {
        //         return vm.articlePackagings.totalCount;
        //     }
        // }];

        function getArticles(query) {
            vm.promise = Restangular
                .one('articles').one('list').get(query)
                .then(
                    function (response) {
                        vm.articles = response;
                    }
                );
        }

        vm.editItem = function (id) {
            $mdDialog.show({
                clickOutsideToClose: false,
                controller: app.name + '.articleItem',
                controllerAs: 'articleItem',
                focusOnOpen: false,
                locals: {id: id},
                //targetEvent: event,
                template: articleItem
            }).then(function (refresh) {
                if (refresh) {
                    getArticles(vm.query);
                }
            });
        };

        vm.deleteItem = function (items) {
            var id = [];
            for (var i = 0; i < items.length; i++) {
                id.push(items[i].id);
            }

            $mdDialog.show({
                clickOutsideToClose: false,
                controller: app.name + '.articleDelete',
                controllerAs: 'articleDelete',
                focusOnOpen: false,
                //targetEvent: event,
                locals: {id: id},
                template: articleDelete
            }).then(function () {
                getArticles(vm.query);
            });

            vm.selected = [];
        };

        vm.printReports = function () {
            $mdDialog.show({
                clickOutsideToClose: false,
                controller: app.name + '.articleIndex',
                controllerAs: 'articleIndex',
                focusOnOpen: false,
                //targetEvent: event,
                template: articlePrintItems
            });
        };

        vm.closeAlert = function () {
            $mdDialog.hide();
        };

        vm.printItems = function () {
            vm.pdf = Restangular
                .one('articles').one('pdf').one('list').withHttpConfig({responseType: 'arraybuffer'}).customGET('', {})
                .then(
                    function (response) {
                        var file = new Blob([response], {type: 'application/pdf'});
                        FileSaver.saveAs(file, 'elenco_articoli.pdf');
                    }
                );
        };

        vm.printItemsCode = function () {
            vm.pdf = Restangular
                .one('articles').one('pdf').one('list').one('code').withHttpConfig({responseType: 'arraybuffer'}).customGET('', {})
                .then(
                    function (response) {
                        var file = new Blob([response], {type: 'application/pdf'});
                        FileSaver.saveAs(file, 'elenco_articoli_codici.pdf');
                    }
                );
        };

        vm.printItem = function (id) {
            vm.pdf = Restangular
                .one('articles').one('pdf').one('detail').withHttpConfig({responseType: 'arraybuffer'}).customGET('', {'id': id})
                .then(
                    function (response) {
                        var file = new Blob([response], {type: 'application/pdf'});
                        FileSaver.saveAs(file, 'dettaglio_articolo.pdf');
                    }
                );
        };

        vm.onPaginate = function (page, limit) {
            getArticles(vm.query);
        };

        vm.onReorder = function (order) {
            getArticles(vm.query);
        };

        vm.openSidefilter = function () {
            $mdSidenav('right').open().then(function () {
                // console.log("toggle right is done");
            });
        };

        vm.closeSidefilter = function () {
            $mdSidenav('right').close().then(function () {
                // console.log("toggle right is done");
                getArticles(vm.query);
            });
        };

        getArticles(vm.query);
    }

    controller.$inject = deps;
    app.controller(fullname, controller);
};
