'use strict';
var controllername = 'packagingIndex';

var packagingItem = require('../views/packagingItem.html');
var packagingDelete = require('../views/packagingDelete.html');

module.exports = function(app) {
    var fullname = app.name + '.' + controllername;
    /*jshint validthis: true */

    var deps = ['$scope', '$mdDialog', '$mdSidenav', 'Restangular', 'FileSaver', 'Blob'];

    function controller($scope, $mdDialog, $mdSidenav, Restangular, FileSaver, Blob) {
        var vm = this;
        vm.packagings = [];
        vm.packagings.totalCount = 0;
        vm.controllername = fullname;
        vm.selected = [];

        vm.query = {
            order: '-a.id',
            limit: 50,
            page: 1
        };

        vm.limitOptions = [50, 250];

        function getPackagings(query) {
            vm.promise = Restangular
                .one('packagings').one('list').get(query)
                .then(
                    function(response) {
                        vm.packagings = response;
                    }
                );
        }

        vm.editItem = function(id) {
            $mdDialog.show({
                clickOutsideToClose: false,
                controller: app.name + '.packagingItem',
                controllerAs: 'packagingItem',
                focusOnOpen: false,
                locals: {id: id},
                //targetEvent: event,
                template: packagingItem
            }).then(function (refresh) {
                if (refresh) {
                    getPackagings(vm.query);
                }
            });
        };

        vm.deleteItem = function(items) {
            var id = [];
            for (var i = 0; i < items.length; i++) {
                id.push(items[i].id);
            }

            $mdDialog.show({
                clickOutsideToClose: false,
                controller: app.name + '.packagingDelete',
                controllerAs: 'packagingDelete',
                focusOnOpen: false,
                //targetEvent: event,
                locals: {id: id},
                template: packagingDelete
            }).then(function () {
                getPackagings(vm.query);
            });

            vm.selected = [];
        };

        vm.printItems = function () {
            vm.pdf = Restangular
                .one('packagings').one('pdf').one('list').withHttpConfig({ responseType: 'arraybuffer' }).customGET('', {})
                .then(
                    function(response) {
                        var file = new Blob([response], { type: 'application/pdf' });
                        FileSaver.saveAs(file, 'elenco_confezionamenti.pdf');
                    }
                );
        };

        vm.printItem = function (id) {
            vm.pdf = Restangular
                .one('packagings').one('pdf').one('detail').withHttpConfig({ responseType: 'arraybuffer' }).customGET('', {'id': id})
                .then(
                    function(response) {
                        var file = new Blob([response], { type: 'application/pdf' });
                        FileSaver.saveAs(file, 'dettaglio_confezionamento.pdf');
                    }
                );
        };

        vm.onPaginate = function(page, limit) {
            getPackagings(vm.query);
        };

        vm.onReorder = function(order) {
            getPackagings(vm.query);
        };

        vm.openSidefilter = function () {
            $mdSidenav('right').open().then(function () {
                // console.log("toggle right is done");
            });
        };

        vm.closeSidefilter = function () {
            $mdSidenav('right').close().then(function () {
                getPackagings(vm.query);
                // console.log("toggle right is done");
            });
        };
        getPackagings(vm.query);
    }

    controller.$inject = deps;
    app.controller(fullname, controller);
};
