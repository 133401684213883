'use strict';
var controllername = 'home';

module.exports = function(app) {
    var fullname = app.name + '.' + controllername;
    /*jshint validthis: true */

    var deps = ['$state', '$cookies'];

    function controller($state, $cookies) {
        var vm = this;
        vm.controllername = fullname;
        vm.roles = $cookies.get('roles');

        vm.goState = function (newState) {
            $state.go(newState);
        };
    }

    controller.$inject = deps;
    app.controller(fullname, controller);
};
