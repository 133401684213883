'use strict';
 /*eslint consistent-this:[2,  "articlePackagingAutocompleteCtrl"] */
var directivename = 'articlePackagingAutocomplete';

module.exports = function(app) {

    // controller
    var controllerDeps = ['Restangular'];
    var controller = function(Restangular) {
        var articlePackagingAutocompleteCtrl = this;
        articlePackagingAutocompleteCtrl.directivename = directivename;

        articlePackagingAutocompleteCtrl.articlePackagings = function (text) {
            return Restangular.one('articles').one('packagings').one('autocomplete').get({'text': text}).then(function (data) {
                return data;
            });
        };

        articlePackagingAutocompleteCtrl.searchTextChange = function (text) {
            //console.log('Text changed to ' + text);
        };

        articlePackagingAutocompleteCtrl.selectedItemChange = function (item) {
            //console.log('Item changed to ' + JSON.stringify(item));
        };
    };
    controller.$inject = controllerDeps;

    /*eslint-disable consistent-this */

    // directive
    var directiveDeps = [];
    var directive = function() {
        return {
            restrict: 'AE',
            scope: {
                acModel: '=',
                acCallbackMethod: '&acCallback',
                acRequired: '='
            },
            controller: controller,
            controllerAs: 'articlePackagingAutocompleteCtrl',
            template: require('./articlePackagingAutocomplete.directive.html')
        };
    };
    directive.$inject = directiveDeps;

    app.directive(directivename, directive);
};
