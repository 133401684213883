'use strict';
var controllername = 'articlePackagingIndex';

var articlePackagingItem = require('../views/articlePackagingItem.html');
var articlePackagingDelete = require('../views/articlePackagingDelete.html');

module.exports = function(app) {
    var fullname = app.name + '.' + controllername;
    /*jshint validthis: true */

    var deps = ['$scope', '$mdDialog', '$mdSidenav', 'Restangular', 'FileSaver', 'Blob'];

    function controller($scope, $mdDialog, $mdSidenav, Restangular, FileSaver, Blob) {
        var vm = this;
        vm.controllername = fullname;
        vm.articlePackagings = [];
        vm.articlePackagings.totalCount = 0;
        vm.selected = [];

        vm.query = {
            order: '-a.id',
            limit: 50,
            page: 1
        };

        vm.limitOptions = [50, 250];

        function getArticlePackagings(query) {
            vm.promise = Restangular
                .one('articles').one('packagings').one('list').get(query)
                .then(
                    function(response) {
                        vm.articlePackagings = response;
                    }
                );
        }

        vm.editItem = function(id) {
            $mdDialog.show({
                clickOutsideToClose: false,
                controller: app.name + '.articlePackagingItem',
                controllerAs: 'articlePackagingItem',
                focusOnOpen: false,
                locals: {id: id},
                //targetEvent: event,
                template: articlePackagingItem
            }).then(function (refresh) {
                if (refresh) {
                    getArticlePackagings(vm.query);
                }
            });
        };

        vm.deleteItem = function(items) {
            var id = [];
            for (var i = 0; i < items.length; i++) {
                id.push(items[i].id);
            }

            $mdDialog.show({
                clickOutsideToClose: false,
                controller: app.name + '.articlePackagingDelete',
                controllerAs: 'articlePackagingDelete',
                focusOnOpen: false,
                //targetEvent: event,
                locals: {id: id},
                template: articlePackagingDelete
            }).then(function () {
                getArticlePackagings(vm.query);
            });

            vm.selected = [];
        };

        vm.printItems = function () {
            vm.pdf = Restangular
                .one('articles').one('packagings').one('pdf').one('list').withHttpConfig({ responseType: 'arraybuffer' }).customGET('', {})
                .then(
                    function(response) {
                        var file = new Blob([response], { type: 'application/pdf' });
                        FileSaver.saveAs(file, 'elenco_articoli_confezionati.pdf');
                    }
                );
        };

        vm.printItem = function (id) {
            vm.pdf = Restangular
                .one('articles').one('packagings').one('pdf').one('detail').withHttpConfig({ responseType: 'arraybuffer' }).customGET('', {'id': id})
                .then(
                    function(response) {
                        var file = new Blob([response], { type: 'application/pdf' });
                        FileSaver.saveAs(file, 'dettaglio_articolo_confezionato.pdf');
                    }
                );
        };

        vm.onPaginate = function(page, limit) {
            getArticlePackagings(vm.query, {page: page, limit: limit});
        };

        vm.onReorder = function(order) {
            getArticlePackagings(vm.query, {order: order});
        };

        vm.openSidefilter = function () {
            $mdSidenav('right').open().then(function () {
                // console.log("toggle right is done");
            });
        };

        vm.closeSidefilter = function () {
            $mdSidenav('right').close().then(function () {
                getArticlePackagings(vm.query);
                // console.log("toggle right is done");
            });
        };

        getArticlePackagings(vm.query);
    }

    controller.$inject = deps;
    app.controller(fullname, controller);
};
