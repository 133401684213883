'use strict';
var servicename = 'dataService';

module.exports = function (app) {

    var dependencies = ['Restangular'];

    function service(Restangular) {
        var article;

        var getArticle = function (code) {
            article.location = '';
            vm.maxQtyPlaceholder = '';
            Restangular.one('oldest').one('article').get({'code': code}).then(function (data) {
                if (data.length > 0) {
                    article.data = data;
                    article.articlePackagingName = data[0].articlePackagingName;
                    article.lot = data[0].lot;
                    article.location = data[0].location;
                    article.expirationDate = data[0].expiration_date;
                    article.totQty = parseInt(data[0].tot_qty);
                    vm.formUnloadManaged.idArticlePackaging = {id: data[0].idArticlePackaging};

                    vm.formUnloadManaged.expirationDate = vm.oldest.expirationDate;
                    vm.formUnload.expirationDate = vm.oldest.expirationDate;

                    vm.maxQtyPlaceholder = vm.formUnload.idArticlePackaging ? vm.oldest.totQty / vm.formUnload.idArticlePackaging.idPackaging.qtyMultiplier : '';
                }
                return data;
            });
        };

        return {
            getArticle: getArticle
        };

    }

    service.$inject = dependencies;
    app.factory(app.name + '.' + servicename, service);
};
