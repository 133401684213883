'use strict';
var controllername = 'warehouseCartItem';

module.exports = function(app) {
    var fullname = app.name + '.' + controllername;
    /*jshint validthis: true */

    var deps = ['$mdDialog', 'toastr', '$timeout', 'Restangular', 'id'];

    function controller($mdDialog, toastr, $timeout, Restangular, id) {
        var vm = this;
        vm.controllername = fullname;
        vm.id = id;
        vm.formData = {};
        vm.formData.idWarehouseCartWarehouseCartRow = [];
        var deletedRows = [];

        if (id) {
            Restangular
                .one('warehouses').one('carts').customGET('', {'id': id}).then(function (data) {
                vm.formData = data;
            });
        } else {
            vm.formData.idWarehouseCartWarehouseCartRow[0] = {};
        }

        vm.deleteWarehouseCartRow = function (warehouseCartRow) {
            var idWarehouseCartWarehouseCartRow = vm.formData.idWarehouseCartWarehouseCartRow;
            for (var i = 0; i < idWarehouseCartWarehouseCartRow.length; i++) {
                if (idWarehouseCartWarehouseCartRow[i].id === warehouseCartRow.id) {
                    vm.formData.idWarehouseCartWarehouseCartRow.splice(i, 1);
                    deletedRows.push(warehouseCartRow.id);
                }
            }
        };

        vm.addWarehouseCartRow = function () {
            vm.formData.idWarehouseCartWarehouseCartRow[vm.formData.idWarehouseCartWarehouseCartRow.length] = vm.formData.id ? {idWarehouseCart: {id: vm.formData.id}} : {};
        };

        vm.confirm = function () {
            if (vm.id) {
                vm.formData.idWarehouseCartWarehouseCartRowDeleted = deletedRows;
                Restangular
                    .one('warehouses').one('carts').customPUT(vm.formData).then(
                    function () {
                        $mdDialog.hide(true);
                        toastr.success('Lista salvata con successo.');
                    },
                    function (res) {
                        toastr.error('Impossibile salvare, ' + res.statusText + '.');
                    }
                );
            } else {
                Restangular
                    .one('warehouses').one('carts').customPOST(vm.formData).then(
                    function () {
                        $mdDialog.hide(true);
                        toastr.success('Lista aggiunta con successo.');
                    },
                    function (res) {
                        toastr.error('Impossibile salvare, ' + res.statusText + '.');
                    }
                );
            }

        };

        vm.getWarehouseArticleStock = function (item) {
            if (item) {
                // Restangular
                //     .one('warehouses').one('article').one('stock').customGET('', {idArticle: item.id}).then(
                //     function (data) {
                //         vm.articleStock = data[0].stock;
                //     }
                // );
                vm.articleStock = item.articleWarehouseTotUnitQty;
            }
        };

        vm.checkArticleStock = function (qty) {
            $timeout(function () {
                if (qty > +vm.articleStock) {
                    vm.qty = '';
                    toastr.error('Attenzione!!! La quantità immessa è superiore alla giacenza attuale.');
                }
            }, 250);
        };

        vm.cancel = function () {
            $mdDialog.hide();
            toastr.info('Operazione annullata.');
        };
    }

    controller.$inject = deps;
    app.controller(fullname, controller);
};
