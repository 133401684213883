'use strict';
var controllername = 'warehouseCartIndex';

var warehouseCartItem = require('../views/warehouseCartItem.html');
var warehouseCartDelete = require('../views/warehouseCartDelete.html');

module.exports = function(app) {
    var fullname = app.name + '.' + controllername;
    /*jshint validthis: true */

    var deps = ['$mdDialog', '$mdSidenav', 'Restangular', 'FileSaver', 'Blob'];

    function controller($mdDialog, $mdSidenav, Restangular, FileSaver, Blob) {
        var vm = this;
        vm.warehouseCarts = [];
        vm.warehouseCarts.totalCount = 0;
        vm.controllername = fullname;
        vm.selected = [];

        vm.query = {
            order: '-a.id',
            limit: 50,
            page: 1
        };

        vm.limitOptions = [50, 250];

        function getWarehouseCarts(query) {
            vm.promise = Restangular
                .one('warehouses').one('carts').one('list').get(query)
                .then(function (response) {
                    vm.warehouseCarts = response;
                });
        }

        vm.editItem = function (id) {
            $mdDialog.show({
                clickOutsideToClose: false,
                controller: app.name + '.warehouseCartItem',
                controllerAs: 'warehouseCartItem',
                focusOnOpen: false,
                locals: {id: id},
                //targetEvent: event,
                template: warehouseCartItem
            }).then(function (refresh) {
                if (refresh) {
                    getWarehouseCarts(vm.query);
                }
            });
        };

        vm.deleteItem = function (items) {
            var id = [];
            for (var i = 0; i < items.length; i++) {
                id.push(items[i].id);
            }

            $mdDialog.show({
                clickOutsideToClose: false,
                controller: app.name + '.warehouseCartDelete',
                controllerAs: 'warehouseCartDelete',
                focusOnOpen: false,
                //targetEvent: event,
                locals: {id: id},
                template: warehouseCartDelete
            }).then(function () {
                getWarehouseCarts(vm.query);
            });

            vm.selected = [];
        };

        vm.printItems = function () {
            vm.pdf = Restangular
                .one('warehouses').one('carts').one('pdf').one('list').withHttpConfig({responseType: 'arraybuffer'}).customGET('', {})
                .then(
                    function (response) {
                        var file = new Blob([response], {type: 'application/pdf'});
                        FileSaver.saveAs(file, 'elenco_articoli.pdf');
                    }
                );
        };

        vm.printItem = function (id) {
            vm.pdf = Restangular
                .one('warehouses').one('carts').one('pdf').one('detail').withHttpConfig({responseType: 'arraybuffer'}).customGET('', {'id': id})
                .then(
                    function (response) {
                        var file = new Blob([response], {type: 'application/pdf'});
                        FileSaver.saveAs(file, 'dettaglio_articolo.pdf');
                    }
                );
        };

        vm.onPaginate = function (page, limit) {
            getWarehouseCarts(vm.query);
        };

        vm.onReorder = function (order) {
            getWarehouseCarts(vm.query);
        };

        vm.openSidefilter = function () {
            $mdSidenav('right').open().then(function () {
                // console.log("toggle right is done");
            });
        };

        vm.closeSidefilter = function () {
            $mdSidenav('right').close().then(function () {
                // console.log("toggle right is done");
                getWarehouseCarts(vm.query);
            });
        };

        getWarehouseCarts(vm.query);
    }

    controller.$inject = deps;
    app.controller(fullname, controller);
};
