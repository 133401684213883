'use strict';
var controllername = 'registryIndex';

var registryItem = require('../views/registryItem.html');
var registryDelete = require('../views/registryDelete.html');

module.exports = function(app) {
    var fullname = app.name + '.' + controllername;
    /*jshint validthis: true */

    var deps = ['$scope', '$mdDialog', '$mdSidenav', 'Restangular', 'FileSaver', 'Blob'];

    function controller($scope, $mdDialog, $mdSidenav, Restangular, FileSaver, Blob) {
        var vm = this;
        vm.registries = [];
        vm.registries.totalCount = 0;
        vm.controllername = fullname;
        vm.selected = [];

        vm.query = {
            order: '-a.id',
            limit: 50,
            page: 1
        };

        vm.limitOptions = [50, 250];

        function getRegistries(query) {
            vm.promise = Restangular
                .one('registries').one('list').get(query)
                .then(
                    function(response) {
                        vm.registries = response;
                    }
                );
        }

        vm.editItem = function(id) {
            $mdDialog.show({
                clickOutsideToClose: false,
                controller: app.name + '.registryItem',
                controllerAs: 'registryItem',
                focusOnOpen: false,
                locals: {id: id},
                //targetEvent: event,
                template: registryItem
            }).then(function (refresh) {
                if (refresh) {
                    getRegistries(vm.query);
                }
            });
        };

        vm.deleteItem = function(items) {
            var id = [];
            for (var i = 0; i < items.length; i++) {
                id.push(items[i].id);
            }

            $mdDialog.show({
                clickOutsideToClose: false,
                controller: app.name + '.registryDelete',
                controllerAs: 'registryDelete',
                focusOnOpen: false,
                //targetEvent: event,
                locals: {id: id},
                template: registryDelete
            }).then(function () {
                getRegistries(vm.query);
            });

            vm.selected = [];
        };

        vm.printItems = function () {
            vm.pdf = Restangular
                .one('registries').one('pdf').one('list').withHttpConfig({ responseType: 'arraybuffer' }).customGET('', {})
                .then(
                    function(response) {
                        var file = new Blob([response], { type: 'application/pdf' });
                        FileSaver.saveAs(file, 'elenco_fornitori.pdf');
                    }
                );
        };

        vm.printItem = function (id) {
            vm.pdf = Restangular
                .one('registries').one('pdf').one('detail').withHttpConfig({ responseType: 'arraybuffer' }).customGET('', {'id': id})
                .then(
                    function(response) {
                        var file = new Blob([response], { type: 'application/pdf' });
                        FileSaver.saveAs(file, 'dettaglio_fornitore.pdf');
                    }
                );
        };

        vm.onPaginate = function(page, limit) {
            getRegistries(vm.query, {page: page, limit: limit});
        };

        vm.onReorder = function(order) {
            getRegistries(vm.query, {order: order});
        };

        vm.openSidefilter = function () {
            $mdSidenav('right').open().then(function () {
                // console.log("toggle right is done");
            });
        };

        vm.closeSidefilter = function () {
            $mdSidenav('right').close().then(function () {
                getRegistries(vm.query);
                // console.log("toggle right is done");
            });
        };

        getRegistries(vm.query);
    }

    controller.$inject = deps;
    app.controller(fullname, controller);
};
