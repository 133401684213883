'use strict';
var angular = require('angular');
require('angular-ui-router');
require('angular-material');

var modulename = 'packaging';

module.exports = function(namespace) {
    var shared = require('../shared')(namespace);
    var fullname = namespace + '.' + modulename;

    var app = angular.module(fullname, ['ui.router', 'ngMaterial', shared.name]);
    // inject:folders start
    require('./controllers')(app);
require('./directives')(app);
    // inject:folders end
    app.namespace = app.namespace || {};
    app.namespace.shared = shared.name;

    var configRoutesDeps = ['$stateProvider', '$urlRouterProvider'];
    var configRoutes = function($stateProvider, $urlRouterProvider) {
        $urlRouterProvider.otherwise('/');
        $stateProvider
            .state('packagingIndex', {
                url: '/packaging_index',
                parent: 'shared',
                views: {
                    'sidenav': {
                        template: require('../shared/views/sidenav.html'),
                        controller: app.namespace.shared + '.sidenav as sidenav'
                    },
                    'toolbar': {
                        template: require('../shared/views/toolbar.html'),
                        controller: app.namespace.shared + '.toolbar as toolbar'
                    },
                    'content': {
                        template: require('./views/packagingIndex.html'),
                        controller: app.name + '.packagingIndex as packagingIndex'
                    }
                }
            })
        ;
    };
    configRoutes.$inject = configRoutesDeps;
    app.config(configRoutes);

    return app;
};
