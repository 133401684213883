'use strict';
var servicename = 'authHandler';

module.exports = function(app) {
    var dependencies = ['$cookies', '$window'];
    function service ($cookies, $window) {
        var authHandler = {};
        // If not authenticated, go to login
        if (typeof $cookies.get('username') == 'undefined' || typeof $cookies.get('secret') == 'undefined') {
            $window.location = '#/login';
        }

        authHandler.username = function() {
            return $cookies.get('username');
        };

        authHandler.secret = function() {
            return $cookies.get('secret');
        };
        return authHandler;
    }
    service.$inject = dependencies;
    app.factory(app.name + '.' + servicename, service);
};
