'use strict';
 /*eslint consistent-this:[2,  "packagingAutocompleteCtrl"] */
var directivename = 'packagingAutocomplete';

module.exports = function(app) {

    // controller
    var controllerDeps = ['Restangular'];
    var controller = function(Restangular) {
        var packagingAutocompleteCtrl = this;
        packagingAutocompleteCtrl.directivename = directivename;

        packagingAutocompleteCtrl.packagings = function (text) {
            return Restangular.one('packagings').one('autocomplete').get({'text': text}).then(function (data) {
                //console.log(data.items);
                return data;
            });
        };

        packagingAutocompleteCtrl.searchTextChange = function (text) {
            //console.log('Text changed to ' + text);
        };

        packagingAutocompleteCtrl.selectedItemChange = function (item) {
            //console.log('Item changed to ' + JSON.stringify(item));
        };
    };
    controller.$inject = controllerDeps;

    /*eslint-disable consistent-this */

    // directive
    var directiveDeps = [];
    var directive = function() {
        return {
            restrict: 'AE',
            scope: {
                acModel: '=',
                ngRequired: '='
            },
            controller: controller,
            controllerAs: 'packagingAutocompleteCtrl',
            // bindToController: true,
            template: require('./packagingAutocomplete.directive.html')
            // compile: function(tElement, tAttrs) {
            //     return {
            //         pre: function(scope, element, attrs) {
            //
            //         },
            //         post: function(scope, element, attrs) {
            //
            //         }
            //     };
            // }
        };
    };
    directive.$inject = directiveDeps;

    app.directive(directivename, directive);
};
