'use strict';
var controllername = 'codeTemplateItem';

module.exports = function(app) {
    var fullname = app.name + '.' + controllername;
    /*jshint validthis: true */

    var deps = ['$mdDialog', 'toastr', 'Restangular', 'id'];

    function controller($mdDialog, toastr, Restangular, id) {
        var vm = this;
        vm.id = id;

        if (id) {
            Restangular
                .one('codes').one('templates').customGET('', {'id': id}).then(function (data) {
                vm.formData = data;
            });
        }

        vm.formData = {};
        vm.confirm = function () {
            //console.log(vm.formData);
            if (id) {
                Restangular
                    .one('codes').one('templates').customPUT(vm.formData).then(
                    function (data) {
                        $mdDialog.hide(true);
                        toastr.success('Regola salvata con successo.');
                    },
                    function (res) {
                        $mdDialog.hide();
                        toastr.error('Impossibile salvare, ' + res.statusText + '.');
                    }
                );
            } else {
                Restangular
                    .one('codes').one('templates').customPOST(vm.formData).then(
                    function (data) {
                        $mdDialog.hide(true);
                        toastr.success('Regola aggiunta con successo.');
                    },
                    function (res) {
                        $mdDialog.hide();
                        toastr.error('Impossibile salvare, ' + res.statusText + '.');
                    }
                );
            }

        };

        vm.cancel = function () {
            $mdDialog.hide();
            toastr.info('Operazione annullata.');
        };
    }

    controller.$inject = deps;
    app.controller(fullname, controller);
};
