'use strict';
var servicename = 'tokenHandler';

var CryptoJS = require('crypto-js');

module.exports = function(app) {
    var dependencies = ['$http', app.name + '.base64'];
    function service ($http, Base64) {
        var tokenHandler = {};
        var token = 'none';

        tokenHandler.set = function (newToken) {
            token = newToken;
        };

        tokenHandler.get = function () {
            return token;
        };

        // Generate random string of length
        tokenHandler.randomString = function (length) {
            var text = '';
            var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            for (var i = 0; i < length; i++) {
                text += possible.charAt(Math.floor(Math.random() * possible.length));
            }
            return text;
        };

        // Date formater to UTC
        var formatDate = function (d) {
            // Padding for date creation
            var pad = function (num) {
                return ('0' + num).slice(-2);
            };

            return [d.getUTCFullYear(),
                    pad(d.getUTCMonth() + 1),
                    pad(d.getUTCDate())].join('-') + 'T' +
                [pad(d.getUTCHours()),
                    pad(d.getUTCMinutes()),
                    pad(d.getUTCSeconds())].join(':') + 'Z';
        };

        tokenHandler.getCredentials = function (username, secret) {
            // Generate nonce
            var nonce = tokenHandler.randomString(30);

            // Creation time of the token
            var created = formatDate(new Date());
            var sha1 = CryptoJS.SHA1;

            // Generating digest from secret, creation and nonce
            var hash = sha1(nonce + created + secret);
            var digest = hash.toString(CryptoJS.enc.Base64);

            // Base64 Encode digest
            var b64nonce = Base64.encode(nonce);

            //console.log(username);
            //console.log(digest);
            //console.log(b64nonce);
            //console.log(created);
            // Return generated token
            return 'UsernameToken Username="' + username + '", PasswordDigest="' + digest + '", Nonce="' + b64nonce + '", Created="' + created + '"';
        };
        return tokenHandler;
    }
    service.$inject = dependencies;
    app.factory(app.name + '.' + servicename, service);
};
