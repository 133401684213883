'use strict';
var servicename = 'digest';

var CryptoJS = require('crypto-js');

module.exports = function(app) {
    var dependencies = ['$q'];
    function service ($q) {
        var factory = {
            // Symfony SHA512 encryption provider
            cipher: function (secret, salt) {
                var deferred = $q.defer();
                var sha512 = CryptoJS.SHA512;

                var salted = secret + '{' + salt + '}';
                var digest = sha512(salted);
                for (var i = 1; i < 5000; i++) {
                    digest = sha512(digest.concat(CryptoJS.enc.Utf8.parse(salted)));
                }
                digest = digest.toString(CryptoJS.enc.Base64);

                deferred.resolve(digest);
                return deferred.promise;
            },
            // Default Symfony plaintext encryption provider
            plain: function (secret, salt) {
                var deferred = $q.defer();

                var salted = secret + '{' + salt + '}';
                var digest = salted;

                deferred.resolve(digest);
                return deferred.promise;
            }
        };
        return factory;
    }
    service.$inject = dependencies;
    app.factory(app.name + '.' + servicename, service);
};
