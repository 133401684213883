'use strict';
var controllername = 'sidenav';

module.exports = function (app) {
    var fullname = app.name + '.' + controllername;
    /*jshint validthis: true */

    var deps = ['$mdSidenav', '$state', '$cookies'];

    function controller($mdSidenav, $state, $cookies) {
        var vm = this;
        vm.controllername = fullname;
        vm.roles = $cookies.get('roles');

        vm.closeSidenav = function () {
            $mdSidenav('left').close().then(function () {
                //$log.debug("close LEFT is done");
            });
        };

        vm.goState = function (newState) {
            $mdSidenav('left').close().then(function () {
                $state.go(newState);
            });
        };
    }

    controller.$inject = deps;
    app.controller(fullname, controller);
};
