'use strict';
 /*eslint consistent-this:[2,  "focusWhenCtrl"] */
var directivename = 'focusWhen';

module.exports = function(app) {

    // controller
    var controllerDeps = [];
    var controller = function() {
        var focusWhenCtrl = this;
        focusWhenCtrl.directivename = directivename;
    };
    controller.$inject = controllerDeps;

    /*eslint-disable consistent-this */

    // directive
    var directiveDeps = [];
    var directive = function() {
        return {
            scope: {
                focusWhen: '='
            },
            link: function($scope, $element) {

                $scope.$watch('focusWhen', function(shouldFocus) {
                    if (shouldFocus) {
                        setTimeout(function(){
                            $element[0].focus();
                        }, 750);
                    }
                });
            }
        };
    };
    directive.$inject = directiveDeps;

    app.directive(directivename, directive);
};
