'use strict';
var controllername = 'articlePackagingDelete';

module.exports = function (app) {
    var fullname = app.name + '.' + controllername;
    /*jshint validthis: true */

    var deps = ['$mdDialog', 'toastr', 'Restangular', 'id'];

    function controller($mdDialog, toastr, Restangular, id) {
        var vm = this;
        vm.id = id;

        function deleteItem(x) {
            Restangular
                .one('articles').one('packagings').customDELETE('', {'id': x}).then(
                function (data) {
                    $mdDialog.hide();
                    toastr.success('Articolo confezionato con id ' + x + ' eliminato con successo.');
                },
                function (res) {
                    $mdDialog.hide();
                    toastr.error('Impossibile eliminare, ' + res.statusText + '.');
                }
            );
        }

        vm.confirmDelete = function () {
            for (var i = 0; i < id.length; i++) {
                deleteItem(id[i]);
            }
        };

        vm.cancelDelete = function () {
            $mdDialog.hide();
        };
    }

    controller.$inject = deps;
    app.controller(fullname, controller);
};
