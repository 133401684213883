'use strict';
 /*eslint consistent-this:[2,  "dateParserCtrl"] */
var directivename = 'dateParser';

var moment = require('moment');

module.exports = function(app) {

    // controller
    var controllerDeps = [];
    var controller = function() {
        var dateParserCtrl = this;
        dateParserCtrl.directivename = directivename;
    };
    controller.$inject = controllerDeps;

    /*eslint-disable consistent-this */

    // directive
    var directiveDeps = [];
    var directive = function() {
        function link(scope, element, attrs, ngModel) {
            var parser = function (val) {
                val = moment(val).format('YYYY-MM-DD');
                return val;
            };

            var formatter = function (val) {
                if (!val) {
                    return val;
                }
                val = new Date(val);
                return val;
            };

            ngModel.$parsers.push(parser);
            ngModel.$formatters.push(formatter);
        }

        return {
            require: 'ngModel',
            link: link,
            restrict: 'A'
        };
    };
    directive.$inject = directiveDeps;

    app.directive(directivename, directive);
};
