'use strict';
var controllername = 'login';

module.exports = function(app) {
    var fullname = app.name + '.' + controllername;
    /*jshint validthis: true */

    var depsLogin = [app.name + '.authHandler', '$scope', '$window', '$cookies', 'Restangular', app.name + '.digest', '$mdDialog']; //[app.name + '.authHandler', '$scope', '$window', '$cookies', 'Restangular', app.name + '.digest', '$mdDialog'];
    function Login(AuthHandler, $scope, $window, $cookies, Restangular, Digest, $mdDialog) {
        var vm = this;
        // On Submit function

        vm.getSalt = function () {
            vm.dataLoading = true;
            var username = vm.username;
            var password = vm.password;

            // Get Salt
            Restangular
                .one('salt').customGET('', {'username': username})
                .then(function (response) {
                    if (response) { // username valid
                        var salt = response.salt;

                        // Encrypt password accordingly to generate secret
                        Digest.cipher(password, salt).then(function (secret) {
                            // Store auth informations in cookies for page refresh
                            $cookies.put('username', username);
                            $cookies.put('secret', secret);

                            Restangular
                                .one('user').customGET('', {'username': username})
                                .then(
                                    function(user) {
                                        // $mdDialog.show(
                                        //     $mdDialog.alert()
                                        //         .clickOutsideToClose(true)
                                        //         .title('Login result')
                                        //         .textContent('Ok')
                                        //         .ariaLabel('Login Alert')
                                        //         .ok('Got it!')
                                        // );

                                        if (user.roles.length > 0) {
                                            $cookies.put('roles', user.roles);
                                        } else {
                                            var roles = [];
                                            for (var g = 0; g < user.groups.length; g++) {
                                                roles.push(user.groups[g].roles);
                                            }
                                            $cookies.put('roles', roles);
                                        }
                                        $window.location = '#/pages';
                                    },
                                    function(res) {
                                        $mdDialog.show(
                                            $mdDialog.alert()
                                                .clickOutsideToClose(true)
                                                .title('Login result')
                                                .textContent('Errore')
                                                .ariaLabel('Alert Dialog Demo')
                                                .ok('Got it!')
                                        );

                                        $cookies.remove('username');
                                        $cookies.remove('secret');
                                        $cookies.remove('roles');
                                    }
                                );
                        }, function (err) {
                            //console.log(err);
                        });
                    } else  { // username invalid
                        vm.dataLoading = false;
                        $mdDialog.show(
                            $mdDialog.alert()
                                .clickOutsideToClose(true)
                                .title('Errore')
                                .textContent('Nome utente o password errati.')
                                .ariaLabel('Alert Dialog Demo')
                                .ok('Ok')
                        );
                    }
                });
        };
    }

    Login.$inject = depsLogin;
    app.controller(fullname, Login);

    // var depsPageController = ['$scope', '$window', 'Restangular', '$mdDialog'];
    // function PageController ($scope, $window, Restangular, $mdDialog) {
    //     $scope.pages = [];
    //     Restangular
    //         .all('pages').getList()
    //         .then(function (response) {
    //             $scope.pages = response;
    //         });
    //
    //     $scope.showPage = function (page) {
    //         $mdDialog.open({
    //             animation: true,
    //             templateUrl: assets_path + '/partials/page/page-show.html',
    //             controller: 'PageModalInstanceCtrl',
    //             resolve: {
    //                 page: page
    //             }
    //         });
    //     };
    //
    //     $scope.editPage = function (page) {
    //         $mdDialog.open({
    //             animation: true,
    //             templateUrl: assets_path + '/partials/page/page-edit.html',
    //             controller: 'PageModalInstanceCtrl',
    //             resolve: {
    //                 page: page
    //             }
    //         });
    //     };
    // }
    // PageController.$inject = depsPageController;
    // app.controller(fullname, PageController);
    //
    // var depsPageModalInstanceCtrl = ['$scope', '$window', 'Restangular', '$mdDialog'];
    // function PageModalInstanceCtrl ($scope, $modalInstance, page) {
    //     $scope.page = page;
    //
    //     $scope.ok = function () {
    //         $modalInstance.close();
    //     };
    //
    //     $scope.cancel = function () {
    //         $modalInstance.dismiss('cancel');
    //     };
    //
    //     $scope.doneEditing = function (page) {
    //         page.title = page.title.trim();
    //         page.put();
    //         $modalInstance.close();
    //     };
    // }
    // PageModalInstanceCtrl.$inject = depsPageModalInstanceCtrl;
    // app.controller(fullname, PageModalInstanceCtrl);
    //
    // var depsModalInstanceCtrl = ['$scope', '$window', 'Restangular', '$mdDialog'];
    // function ModalInstanceCtrl ($scope, $modalInstance, items, title, introduction) {
    //     $scope.items = items;
    //     $scope.title = title;
    //     $scope.introduction = introduction;
    //
    //
    //     $scope.ok = function () {
    //         $modalInstance.close();
    //     };
    // }
    // ModalInstanceCtrl.$inject = depsModalInstanceCtrl;
    // app.controller(fullname, ModalInstanceCtrl);

    // var depsAuthController = ['$state'];
    // function AuthController($state, AuthFactory) {
    //     var vmAuth = this;
    //     vmAuth.mess = function() {
    //         alert("Prova");
    //     };
    //
    //     vmAuth.login = function() {
    //         vmAuth.dataLoading = true;
    //         AuthFactory.Login(vmAuth.username, vmAuth.password, function (response) {
    //             if (response.success) {
    //                 AuthFactory.SetCredentials(vmAuth.username, vmAuth.password);
    //                 $state.go('main');
    //             } else {
    //                 //FlashService.Error(response.message);
    //                 console.log("login error");
    //                 vmAuth.dataLoading = false;
    //             }
    //         });
    //         alert("yeah");
    //     };
    //
    //     vmAuth.login = login;
    // }
    // AuthController.$inject = depsAuthController;
    // app.controller(fullname, AuthController);
};
