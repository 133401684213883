'use strict';
var controllername = 'toolbar';

module.exports = function (app) {
    var fullname = app.name + '.' + controllername;
    /*jshint validthis: true */

    var deps = ['$mdSidenav'];

    function controller($mdSidenav) {
        var vm = this;
        vm.controllername = fullname;

        vm.openSidenav = function () {
            $mdSidenav('left').open().then(function () {
                //$log.debug("close LEFT is open");
            });
        };
    }

    controller.$inject = deps;
    app.controller(fullname, controller);
};
