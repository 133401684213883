'use strict';
var controllername = 'codeTemplateIndex';

var codeTemplateItem = require('../views/codeTemplateItem.html');
var codeTemplateDelete = require('../views/codeTemplateDelete.html');

module.exports = function(app) {
    var fullname = app.name + '.' + controllername;
    /*jshint validthis: true */

    var deps = ['$scope', '$mdDialog', '$mdSidenav', 'Restangular', 'FileSaver', 'Blob'];

    function controller($scope, $mdDialog, $mdSidenav, Restangular, FileSaver, Blob) {
        var vm = this;
        vm.controllername = fullname;
        vm.codeTemplates = [];
        vm.codeTemplates.totalCount = 0;
        vm.selected = [];

        vm.query = {
            order: '-a.id',
            limit: 50,
            page: 1
        };

        vm.limitOptions = [50, 250];

        function getCodeTemplates(query) {
            vm.promise = Restangular
                .one('codes').one('templates').one('list').get(query)
                .then(
                    function(response) {
                        vm.codeTemplates = response;
                    }
                );
        }

        vm.editItem = function(id) {
            $mdDialog.show({
                clickOutsideToClose: false,
                controller: app.name + '.codeTemplateItem',
                controllerAs: 'codeTemplateItem',
                focusOnOpen: false,
                locals: {id: id},
                //targetEvent: event,
                template: codeTemplateItem
            }).then(function (refresh) {
                if (refresh) {
                    getCodeTemplates(vm.query);
                }
            });
        };

        vm.deleteItem = function(items) {
            var id = [];
            for (var i = 0; i < items.length; i++) {
                id.push(items[i].id);
            }

            $mdDialog.show({
                clickOutsideToClose: false,
                controller: app.name + '.codeTemplateDelete',
                controllerAs: 'codeTemplateDelete',
                focusOnOpen: false,
                //targetEvent: event,
                locals: {id: id},
                template: codeTemplateDelete
            }).then(function () {
                getCodeTemplates(vm.query);
            });

            vm.selected = [];
        };

        vm.printItems = function () {
            vm.pdf = Restangular
                .one('codes').one('templates').one('pdf').one('list').withHttpConfig({ responseType: 'arraybuffer' }).customGET('', {})
                .then(
                    function(response) {
                        var file = new Blob([response], { type: 'application/pdf' });
                        FileSaver.saveAs(file, 'elenco_articoli_confezionati.pdf');
                    }
                );
        };

        vm.printItem = function (id) {
            vm.pdf = Restangular
                .one('codes').one('templates').one('pdf').one('detail').withHttpConfig({ responseType: 'arraybuffer' }).customGET('', {'id': id})
                .then(
                    function(response) {
                        var file = new Blob([response], { type: 'application/pdf' });
                        FileSaver.saveAs(file, 'dettaglio_articolo_confezionato.pdf');
                    }
                );
        };

        vm.onPaginate = function(page, limit) {
            getCodeTemplates(vm.query, {page: page, limit: limit});
        };

        vm.onReorder = function(order) {
            getCodeTemplates(vm.query, {order: order});
        };

        vm.openSidefilter = function () {
            $mdSidenav('right').open().then(function () {
                // console.log("toggle right is done");
            });
        };

        vm.closeSidefilter = function () {
            $mdSidenav('right').close().then(function () {
                getCodeTemplates(vm.query);
                // console.log("toggle right is done");
            });
        };

        getCodeTemplates(vm.query);
    }

    controller.$inject = deps;
    app.controller(fullname, controller);
};
